import React, { useState } from 'react';
import { Form, Input, message, Row } from 'antd';
import styles from './login.module.scss';
import { validateEmail, validatePassword } from 'src/validation/user.validator';
import { useTranslation } from 'react-i18next';
import { i18nKey } from 'src/locales/i18n';
import { Link } from 'react-router-dom';
import passwordIcon from 'src/assets/icons/ic_password.svg';
import userIcon from 'src/assets/icons/ic_user.svg';
import ProductButton from 'src/components/product-button/product-button';
import { PAGE_ROUTE } from 'src/constants/route';
import { AuthService } from 'src/services/auth.service';
import { TAB_KEY } from 'src/components/modal/login/login-modal';
import Container from 'typedi';
import { ILoginBody, LoginDTO } from 'src/model/dto/login.dto';
import { LOADING_STATUS, USER_AUTH_STATUS } from 'src/constants/status';
import { AuthStore } from 'src/stores/auth.store';

export interface IProps {
    onFinish: () => void;
    submitLoading?: boolean;
    authStore: AuthStore;
}

export enum USER_FIELDS {
    EMAIL = 'email',
    PASSWORD = 'password'
}

const LoginFormV1 = ({ onFinish, authStore }: IProps) => {
    const [form] = Form.useForm();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const authService = Container.get(AuthService);

    const USER_AUTHENTICATION_ERROR_MAP: {
        [key in USER_AUTH_STATUS]?: { key: USER_FIELDS | undefined; msg: string };
    } = {
        [USER_AUTH_STATUS.EMAIL_DOES_NOT_MATCH]: {
            key: USER_FIELDS.EMAIL,
            msg: t(i18nKey.msgEmailNotExist)
        },
        [USER_AUTH_STATUS.PASSWORD_DOES_NOT_MATCH]: {
            key: USER_FIELDS.PASSWORD,
            msg: t(i18nKey.msgWrongPassWord)
        },
        [USER_AUTH_STATUS.FAILED]: { key: undefined, msg: t(i18nKey.msgSystemError) }
    };

    const onFinishForm = async (values: ILoginBody) => {
        setLoading(true);
        const loginDTO = new LoginDTO({
            email: values.email,
            password: values.password
        });
        authService.signIn(loginDTO).then((result) => {
            const { msgSts, data } = result;
            setLoading(false);
            if (msgSts?.code === LOADING_STATUS.SUCCESS) {
                authStore.setUser(data);
                onFinish();
            } else {
                const msg = Object.keys(USER_AUTHENTICATION_ERROR_MAP).includes(
                    msgSts?.code as USER_AUTH_STATUS
                )
                    ? (msgSts?.code as USER_AUTH_STATUS)
                    : USER_AUTH_STATUS.FAILED;
                const error = USER_AUTHENTICATION_ERROR_MAP[msg];
                if (!error || !error.key) {
                    message.error(i18nKey.msgSystemError);
                    return;
                }

                form.setFields([
                    {
                        name: error.key,
                        errors: [error.msg]
                    }
                ]);
            }
        });
    };

    return (
        <Form
            className={styles.formLogin}
            initialValues={{ remember: true }}
            onFinish={onFinishForm}
            form={form}
            autoComplete="off"
            onFieldsChange={(_, someFields) => {
                const fieldsValue: { [key in string]: string } = form.getFieldsValue([
                    USER_FIELDS.EMAIL,
                    USER_FIELDS.PASSWORD
                ]);
                const isSomeFieldBeEmpty = Object.values(fieldsValue).some((value) => !value);
                const isSomeFieldBeError = someFields.some((field) => field.errors?.length);
                setIsSubmitDisabled(isSomeFieldBeEmpty || isSomeFieldBeError);
            }}>
            <Row justify="center">
                <div className={styles.loginDesc}>
                    <p>{t(i18nKey.login.loginTitle)}</p>
                </div>
            </Row>
            <Row justify="center">
                <Form.Item
                    className={styles.formInput}
                    name={USER_FIELDS.EMAIL}
                    key={USER_FIELDS.EMAIL}
                    rules={[
                        () => ({
                            validator: (_, value) => {
                                return validateEmail(value);
                            }
                        })
                    ]}>
                    <Input
                        className={styles.inputField}
                        autoFocus={true}
                        prefix={<img src={userIcon} />}
                        placeholder={t(i18nKey.txtInputEmailRequired)}
                    />
                </Form.Item>
            </Row>
            <Row justify="center">
                <Form.Item
                    className={styles.formInput}
                    name={USER_FIELDS.PASSWORD}
                    key={USER_FIELDS.PASSWORD}
                    rules={[
                        () => ({
                            validator(_, value) {
                                return validatePassword(value);
                            }
                        })
                    ]}>
                    <Input.Password
                        className={styles.inputField}
                        prefix={<img src={passwordIcon} />}
                        placeholder={t(i18nKey.placeRequirePassword)}
                    />
                </Form.Item>
            </Row>
            <Row justify="center">
                <Link
                    className={styles.forgotPassword}
                    to={{
                        pathname: PAGE_ROUTE.FORGOT_PASSWORD
                    }}>
                    {t(i18nKey.login.forgotPassWord)}
                </Link>
            </Row>
            <Row justify="center">
                <Form.Item className={styles.formInput}>
                    <ProductButton
                        className={styles.btnLogin}
                        htmlType="submit"
                        type="primary"
                        disabled={isSubmitDisabled}
                        loading={isLoading}>
                        {t(i18nKey.btnLogin)}
                    </ProductButton>
                </Form.Item>
            </Row>
            <Row justify="center">
                <p
                    className={styles.register}
                    onClick={() => authStore.setActiveTab(TAB_KEY.REGISTER)}>
                    {t(i18nKey.login.haveNotAccount)}
                </p>
            </Row>
        </Form>
    );
};

export default LoginFormV1;

import React, { Suspense } from 'react';
import { Spin } from 'antd';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'src/locales/i18n';
import { PAGE_ROUTE } from './constants/route';
import { productStore } from './stores/product.store';
import AdminRouter from './components/admin/AdminRouter';
const ForgotPassPage = React.lazy(() => import('src/pages/auth/forgot-pass/forgot-pass.page'));
const ResetPassPage = React.lazy(() => import('src/pages/auth/reset-pass/reset-pass.page'));
const NotFoundPage = React.lazy(() => import('src/pages/404/not-found.page'));
const AdminMainPage = React.lazy(() => import('src/pages/admin/admin-main.page'));
const MasterPage = React.lazy(() => import('src/pages/user/master.page'));
const HomeScreen = React.lazy(() => import('src/pages/user/home-page/home.page'));

const App = () => {
    return (
        <div className="App">
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Spin />}>
                    <BrowserRouter>
                        <Switch>
                            <Route
                                path={PAGE_ROUTE.HOME}
                                render={() => <HomeScreen productStore={productStore} />}
                                exact
                            />
                            <Route
                                path={PAGE_ROUTE.VIEW}
                                render={() => (
                                    <MasterPage headerId="" headerStyle="headerTopFixed" />
                                )}
                            />
                            <Route
                                path={PAGE_ROUTE.USER}
                                render={() => (
                                    <MasterPage headerId="" headerStyle="headerTopFixed" />
                                )}
                            />
                            <Route path={PAGE_ROUTE.PRODUCT} component={MasterPage}></Route>
                            <Route
                                path={PAGE_ROUTE.FORGOT_PASSWORD}
                                component={ForgotPassPage}
                                exact
                            />
                            <Route
                                path={PAGE_ROUTE.RESET_PASSWORD}
                                component={ResetPassPage}
                                exact
                            />
                            <Route path={PAGE_ROUTE.NOT_FOUND} component={NotFoundPage} exact />
                            <AdminRouter path={PAGE_ROUTE.ADMIN} component={AdminMainPage} />
                            <Route path="*">
                                <Redirect to={PAGE_ROUTE.NOT_FOUND} />
                            </Route>
                        </Switch>
                    </BrowserRouter>
                </Suspense>
            </I18nextProvider>
        </div>
    );
};

export default App;

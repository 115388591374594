import { Modal, ModalProps, Tabs } from 'antd';
import React from 'react';
import styles from './login-modal.module.scss';
import LoginFormV1 from 'src/pages/auth/login/login.form';
// import RegisterForm from 'src/pages/auth/register/register.form';
import { observer } from 'mobx-react';
import { BREAKPOINT_CONFIG } from 'src/constants/layout';
import { i18nKey } from 'src/locales/i18n';
import { useTranslation } from 'react-i18next';
import { AuthStore } from 'src/stores/auth.store';
import FirebaseRegisterForm from 'src/pages/auth/register/register-firebase.form';
const { TabPane } = Tabs;

interface IProps {
    onCancel: () => void;
    visible: boolean;
    authStore: AuthStore;
    onLogin: () => void;
    onRegister: (values: { email: string; password: string }) => void;
}

export enum TAB_KEY {
    LOGIN = 'login',
    REGISTER = 'register'
}

const LoginModal = observer(
    ({ onLogin, onRegister, onCancel, visible, authStore, ...props }: IProps & ModalProps) => {
        const { t } = useTranslation();
        const onFinishLogin = () => onLogin();
        const onFinishLoginRegister = (values: { email: string; password: string }) =>
            onRegister({
                email: values.email,
                password: values.password
            });

        const handleModeChange = (e: string) => {
            authStore.setActiveTab(e as TAB_KEY);
        };

        const handleCancel = () => {
            onCancel && onCancel();
        };

        return (
            <Modal
                visible={visible}
                centered
                closable={true}
                footer={null}
                maskClosable={true}
                onCancel={handleCancel}
                className={`${styles.loginMain} ${
                    window.screen.availWidth >= BREAKPOINT_CONFIG.TABLET && styles.transparentForm
                }`}
                {...props}>
                <div
                    className={
                        window.screen.availWidth >= BREAKPOINT_CONFIG.TABLET
                            ? styles.leftForm
                            : styles.topForm
                    }>
                    <Tabs
                        tabPosition={
                            window.screen.availWidth >= BREAKPOINT_CONFIG.TABLET ? 'left' : 'top'
                        }
                        activeKey={authStore.activeTab}
                        onChange={(e) => {
                            handleModeChange(e);
                        }}>
                        <TabPane tab={t(i18nKey.login.txtLogin)} key={TAB_KEY.LOGIN}>
                            <div className={styles.loginForm}>
                                <LoginFormV1 authStore={authStore} onFinish={onFinishLogin} />
                            </div>
                        </TabPane>
                        <TabPane tab={t(i18nKey.login.txtRegister)} key={TAB_KEY.REGISTER}>
                            <div className={styles.loginForm}>
                                <FirebaseRegisterForm onFinish={onFinishLoginRegister} />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </Modal>
        );
    }
);

export default LoginModal;

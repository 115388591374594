import { action, makeAutoObservable, observable } from 'mobx';
import Container, { Service } from 'typedi';
import { HttpService } from '../services/http.service';
import { LOADING_STATUS } from 'src/constants/status';
import { CUSTOMER_TYPE, USER_ROLE } from 'src/constants/default';
import { TAB_KEY } from 'src/components/modal/login/login-modal';
import { Customer } from 'src/model/business/customer';
import { GetCustomerDTO } from 'src/model/dto/customer.dto';
export const USER_INFO = 'user_info';

@Service()
export class AuthStore {
    public user?: Customer;
    public activeTab = TAB_KEY.LOGIN;
    httpService = Container.get(HttpService);

    constructor() {
        const user = localStorage.getItem(USER_INFO);
        user && (this.user = JSON.parse(user));

        this.freshLoginUser();
        makeAutoObservable(this, {
            user: observable,
            activeTab: observable,
            setActiveTab: action,
            setUser: action
        });
    }

    setUser(user: Partial<Customer>) {
        if (this.user) {
            this.user = { ...this.user, ...user };
        } else {
            this.user = {
                id: user.id,
                email: user?.email || '',
                name: user.name || '',
                role: user.role || USER_ROLE.USER,
                customerId: user.customerId || 0,
                contactNumber: user.contactNumber || '',
                address: user.address,
                locationName: user.locationName,
                wardName: user.wardName,
                customerType: user.customerType || CUSTOMER_TYPE.STANDARD,
                totalPoint: user.totalPoint || 0
            };
        }
        localStorage.setItem(
            USER_INFO,
            JSON.stringify({
                customerId: user?.customerId,
                email: user?.email || '',
                name: user.name || '',
                contactNumber: user.contactNumber || '',
                address: user.address,
                locationName: user.locationName,
                wardName: user.wardName
            })
        );
    }

    setActiveTab(activeTab: TAB_KEY) {
        this.activeTab = activeTab;
    }

    signOut() {
        this.user = undefined;
        this.httpService.deleteAccessToken();
        localStorage.removeItem(USER_INFO);
    }

    isSignedIn(): boolean {
        const tokenContent = this.httpService.getTokenInfo();
        return !!this.user && !!tokenContent && tokenContent.exp * 1000 > new Date().getTime();
    }

    isAdmin(): boolean {
        const tokenContent = this.httpService.getTokenInfo();
        return (
            !!this.user &&
            !!tokenContent &&
            tokenContent.exp * 1000 > new Date().getTime() &&
            tokenContent?.role !== USER_ROLE.USER
        );
    }

    // Do not save user information to local storage
    // Just retrieve data on reload page
    private async freshLoginUser(): Promise<void> {
        console.log('DEBUG LOG:::freshLoginUser');
        // Fresh product with discount
        const tokenInfo = this.httpService.getTokenInfo();
        if (!tokenInfo || !tokenInfo.userId) {
            return;
        }
        // Load user
        const dto = new GetCustomerDTO({
            id: tokenInfo.userId
        });
        const payload = await this.httpService.request(dto);
        const { msgSts, data } = payload;
        if (msgSts?.code === LOADING_STATUS.SUCCESS && data) {
            this.setUser({
                id: data.id,
                email: data?.email,
                name: data.name,
                role: data.role,
                customerId: data.customerId,
                contactNumber: data.contactNumber,
                address: data.address,
                locationName: data.locationName,
                wardName: data.wardName,
                customerType: data.customerType,
                totalPoint: data.totalPoint
            });
            localStorage.setItem(
                USER_INFO,
                JSON.stringify({
                    customerId: data?.customerId,
                    email: data?.email || '',
                    name: data.name || '',
                    contactNumber: data.contactNumber || '',
                    address: data.address,
                    locationName: data.locationName,
                    wardName: data.wardName
                })
            );
        }
    }
}

export const authStore = new AuthStore();

import { ResetPassDTO, ResetPassResponse } from 'src/model/dto/reset-pass.dto';
import { LoginDTO } from 'src/model/dto/login.dto';
import { ChangePassDTO, ChangePasswordResponse } from 'src/model/dto/change-pass.dto';
import Container, { Service } from 'typedi';
import { HttpService } from './http.service';
import { ForgotPassDTO, ForgotPassResponse } from 'src/model/dto/reset-user-pass.dto';
import { ResponseDTO } from 'src/model/dto/base.dto';

@Service()
export class AuthService {
    httpService = Container.get(HttpService);

    async signIn(loginDTO: LoginDTO): Promise<ResponseDTO> {
        return this.httpService.request(loginDTO);
    }

    async getResetPass(resetPassDTO: ResetPassDTO): Promise<ResetPassResponse> {
        return this.httpService.request(resetPassDTO);
    }

    async getChangePass(changePassDTO: ChangePassDTO): Promise<ChangePasswordResponse> {
        return this.httpService.request(changePassDTO);
    }

    async getForgotPass(forgotPassDTO: ForgotPassDTO): Promise<ForgotPassResponse> {
        return this.httpService.request(forgotPassDTO);
    }
}

import { ENDPOINT } from 'src/constants/endpoint';
import { METHOD } from 'src/constants/http';
import { DTO, IPagination, ResponseDTO } from './base.dto';
import { IImage } from './product.dto';

export interface ILayout {
    id?: string;
    name?: string;
    title?: string;
    subTitle?: string;
    position?: string;
    image?: IImage;
    isActive?: boolean;
    isVideo?: boolean;
    imageId?: string;
}
export class GetAllLayoutResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data:
        | {
              items: ILayout[];
              totalItems: number;
          }
        | undefined;
}

export interface ILayoutFilter {
    id?: number[];
    keyword?: string;
    title?: string;
    subTitle?: string;
    position?: number;
    isActive?: true;
}

export class CreateOrUpdateLayoutDTO extends DTO {
    public body: FormData | undefined;
    public param: undefined;
    public url = ENDPOINT.SYSTEM_LAYOUT_CREATE_OR_UPDATE;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    public query: ILayout;
    public headers = { 'Content-Type': 'multipart/form-data' };
    constructor(body: FormData | undefined, query: ILayout) {
        super();
        this.body = body;
        this.query = query;
    }
}
export class GetLayoutDTO extends DTO {
    public body: undefined;
    public param: { id: string };
    public query: undefined;
    public url = ENDPOINT.SYSTEM_LAYOUT_GET;
    public method = METHOD.GET;
    public readonly response!: ResponseDTO;
    constructor(param: { id: string }) {
        super();
        this.param = param;
    }
}

export class LayoutPaginationDTO extends DTO {
    public query: IPagination;
    public param: undefined;
    public url = ENDPOINT.SYSTEM_LAYOUT_GET_ALL;
    public method = METHOD.POST;
    public readonly response!: GetAllLayoutResponse;
    public body: ILayoutFilter | undefined;
    constructor(query: IPagination, body: ILayoutFilter | undefined) {
        super();
        this.query = query;
        this.body = body;
    }
}

export class DeleteLayoutDTO extends DTO {
    public body: undefined;
    public param: { id: string };
    public query: undefined;
    public url = ENDPOINT.SYSTEM_LAYOUT_DELETE;
    public method = METHOD.DELETE;
    public readonly response!: ResponseDTO;
    constructor(param: { id: string }) {
        super();
        this.param = param;
    }
}

export class DeleteMultiLayoutsDTO extends DTO {
    public body: { ids: number[] };
    public param: undefined;
    public query: undefined;
    public url = ENDPOINT.SYSTEM_LAYOUT_DELETE_MULTI;
    public method = METHOD.DELETE;
    public readonly response!: ResponseDTO;
    constructor(body: { ids: number[] }) {
        super();
        this.body = body;
    }
}

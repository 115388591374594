import { ENDPOINT } from 'src/constants/endpoint';
import { METHOD } from 'src/constants/http';
import { DTO } from './base.dto';

export interface IRegisterBody {
    email: string;
    password: string;
    name: string;
    contactNumber?: string;
}

export class CustomerRegisterDTO extends DTO {
    public param: undefined;
    public url = ENDPOINT.REGISTER;
    public method = METHOD.POST;
    body: IRegisterBody;
    public query: undefined;
    constructor(body: IRegisterBody) {
        super();
        this.body = body;
    }
}

export class CustomerVerifyInfoDTO extends DTO {
    public param: undefined;
    public url = ENDPOINT.VERIFY_INFO;
    public method = METHOD.POST;
    body: IRegisterBody;
    public query: undefined;
    constructor(body: IRegisterBody) {
        super();
        this.body = body;
    }
}

import { action, makeAutoObservable, observable } from 'mobx';
import { LOADING_STATUS } from 'src/constants/status';
import { ResponseDTO } from 'src/model/dto/base.dto';
import {
    CreateOrUpdateProductEntryDTO,
    DeleteMultiItemDTO,
    DeleteProductEntryDTO,
    GetAllProductEntryDTO,
    GetAllProductEntryResponse,
    GetProductEntryResponse,
    GetSaleCoverDTO,
    ICategory,
    IDiscount,
    ProductEntryPaginationDTO,
    SyncProductAttributeDTO,
    UpdateCategoryDisplayHomepageDTO,
    UpdateSaleCoverDTO
} from 'src/model/dto/product-entry.dto';
import Container, { Service } from 'typedi';
import { HttpService } from './http.service';
@Service()
export class ProductEntryServices {
    public selectedCategory: ICategory = {};
    public selectedDiscount: IDiscount = {};
    public saleCover = '';
    private httpServiceInstance = Container.get(HttpService);

    constructor() {
        this.freshSaleCover();
        makeAutoObservable(this, {
            selectedCategory: observable,
            selectedDiscount: observable,
            saleCover: observable,
            setSelectedCategory: action,
            setSaleCover: action
        });
    }

    setSelectedCategory(category: ICategory | undefined) {
        this.selectedCategory = { ...category };
    }

    setSaleCover(url: string) {
        this.saleCover = url;
    }

    // Do not save user information to local storage
    // Just retrieve data on reload page
    private async freshSaleCover(): Promise<void> {
        const dto = new GetSaleCoverDTO();
        this.httpServiceInstance.request(dto).then((result) => {
            const { msgSts, data } = result;
            if (msgSts?.code === LOADING_STATUS.SUCCESS && data && data.image) {
                this.setSaleCover(data.image.url);
            }
        });
    }

    async getAllProductEntry(
        getAllDTO: GetAllProductEntryDTO
    ): Promise<GetAllProductEntryResponse> {
        return this.httpServiceInstance.request(getAllDTO);
    }

    async getProductEntryByType(
        getEntryDTO: ProductEntryPaginationDTO
    ): Promise<GetProductEntryResponse> {
        return this.httpServiceInstance.request(getEntryDTO);
    }

    async deleteProductEntryByType(deleteColor: DeleteProductEntryDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(deleteColor);
    }

    async deleteMultiItemByType(dto: DeleteMultiItemDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async synProductAttributes(syncDTO: SyncProductAttributeDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(syncDTO);
    }

    async createOrUpdateProductEntry(dto: CreateOrUpdateProductEntryDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async updateCategoryDisplayHomePage(
        dto: UpdateCategoryDisplayHomepageDTO
    ): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async getSaleCover(dto: GetSaleCoverDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async updateSaleCover(dto: UpdateSaleCoverDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }
}

export const productEntryStore = new ProductEntryServices();

export enum PAGINATION_DEFAULT {
    PAGE_DEFAULT = 1,
    SIZE_DEFAULT = 10,
    SIZE_PRODUCT = 12,
    TOTAL_PAGE_DEFAULT = 1,
    SIZE_DISPLAY_HOME = 3,
    SIZE_NO_LIMIT = 0
}

export enum SUPPORT_EXTENSIONS {
    IMAGE = '.jpg, .png, .jpeg',
    EXCEL = '.xls, .csv, .xlsx',
    VIDEO = '.mp4'
}

export enum CAROUSEL_DEFAULT {
    SIZE_DEFAULT = 3
}

export enum BEST_PRODUCT_IMG_DEFAULT {
    WIDTH = 412,
    HEIGHT = 512
}

export enum ProductEntryType {
    Color = 1,
    Category = 2,
    Image = 3,
    Discount = 4,
    Size = 5,
    SizeGuide = 6,
    BankAccount = 7
}

export const DEFAULT_EMPTY_STRING = '';
export const DEBOUNCE_TIME_DEFAULT = 500; //0.5s
export const DEBOUNCE_TIME_SEARCH = 200; //0.5s
export const DATE_TIME_FORMAT = 'DD/MM/yyyy HH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';

export enum PRODUCT_MODE {
    EDIT = 'edit',
    CLONE = 'clone',
    NEW = 'new'
}

export enum PAYMENT_RESULT {
    SUCCESS = 'success',
    FAILED = 'failed'
}

export enum USER_ROLE {
    ADMIN = 1,
    STAFF = 2,
    USER = 3
}

export enum ROLE_NAME {
    ADMIN = 'Admin',
    STAFF = 'Staff',
    USER = 'User'
}

export const ROLE_NAME_MAPPING: { [key in USER_ROLE]: ROLE_NAME } = {
    [USER_ROLE.ADMIN]: ROLE_NAME.ADMIN,
    [USER_ROLE.STAFF]: ROLE_NAME.STAFF,
    [USER_ROLE.USER]: ROLE_NAME.USER
};

export const ROLE_MAPPING = [
    { roleId: USER_ROLE.ADMIN, roleName: ROLE_NAME.ADMIN },
    { roleId: USER_ROLE.STAFF, roleName: ROLE_NAME.STAFF },
    { roleId: USER_ROLE.USER, roleName: ROLE_NAME.USER }
];

export enum EnumPaymentMethod {
    CASH = 1,
    TRANSFER = 2
}

export enum PAYMENT_NAME {
    CASH = 'Cash',
    TRANSFER = 'Transfer'
}

export enum EnumDeliveryMethod {
    STANDARD = 1,
    FAST = 2
}

export const SHIPPING_STANDARD_FEE = 25000;
export const SHIPPING_ADVANCE_FEE = 50000;

export enum LAYOUT_TITLE_POSITION {
    CENTER = 'CENTER',
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export const LAYOUT_POSITION_MAPPING = [
    LAYOUT_TITLE_POSITION.CENTER,
    LAYOUT_TITLE_POSITION.LEFT,
    LAYOUT_TITLE_POSITION.RIGHT
];

export const DEFAULT_OTP_TIME = 60; // 60 seconds
export const OTP_LENGTH = 6;
export const OTP_TIME_FORMAT = 'm:ss';

export enum SYNC_OPTION {
    FIRST_100 = 100,
    FIRST_200 = 200,
    FIRST_500 = 500,
    FIRST_1000 = 1000,
    ALL_ITEMS = 10000
}

export const SYNC_OPTION_MAPPING = [
    { value: SYNC_OPTION.FIRST_100, text: 'Sync 100' },
    { value: SYNC_OPTION.FIRST_200, text: 'Sync 200' },
    { value: SYNC_OPTION.FIRST_500, text: 'Sync 500' },
    { value: SYNC_OPTION.FIRST_1000, text: 'Sync 1000' },
    { value: SYNC_OPTION.ALL_ITEMS, text: 'Sync all' }
];

export const SYSTEM_EMAIL = 'billiongirl.vn@gmail.com';

export enum CUSTOMER_TYPE {
    STANDARD = 1,
    GOLD = 2,
    DIAMOND = 3
}

// Percent
export enum CUSTOMER_TYPE_SALE {
    STANDARD = 0,
    GOLD = 10,
    DIAMOND = 15
}

export enum CUSTOMER_TYPE_NAME {
    STANDARD = 'Standard',
    GOLD = 'Gold',
    DIAMOND = 'Diamond'
}

export const CUSTOMER_TYPE_MAPPING: { [key in CUSTOMER_TYPE]: CUSTOMER_TYPE_NAME } = {
    [CUSTOMER_TYPE.STANDARD]: CUSTOMER_TYPE_NAME.STANDARD,
    [CUSTOMER_TYPE.GOLD]: CUSTOMER_TYPE_NAME.GOLD,
    [CUSTOMER_TYPE.DIAMOND]: CUSTOMER_TYPE_NAME.DIAMOND
};

export const GOLD_MEMBER_DISCOUNT = 0.1; // 10%
export const DIAMOND_MEMBER_DISCOUNT = 0.15; // 15%

export const IMAGE_QUALITY = 0.3;

export const DefaultBankAccount = {
    id: 947,
    name: 'NGUYỄN TƯỜNG VI',
    accountNumber: '0699969996999',
    retailerId: 945194
};

export const MIN_CHARACTER_NAME = 3;
export const MAX_CHARACTER_NAME = 24;
export const IMAGE_DISPLAY_WIDTH = 22;
export const IMAGE_DISPLAY_HEIGHT = 25;
export const IMAGE_SQUARE_RATIO = 4 / 4;
export const IMAGE_COVER_RATIO = 16 / 9;
export const IMAGE_SIZE_GUIDE_RATIO = 183 / 103;
export const MAX_UPLOAD_IMAGES = 8;

export enum FE_SORT_DIRECTION {
    DECS = 'descend',
    ASC = 'ascend'
}

export enum PAGE_ROUTE {
    HOME = '/',
    LOGIN = '/login',
    REGISTER = '/register',
    TEST = '/test',
    ADMIN = '/admin',
    NOT_FOUND = '/404',
    FORGOT_PASSWORD = '/forgot-password',
    RESET_PASSWORD = '/reset-password',
    CHANGE_PASSWORD = '/admin/change-password',
    SYSTEM_MANAGEMENT = '/admin/system-management',
    COLOR_MANAGEMENT = '/admin/system-management/color',
    IMAGE_MANAGEMENT = '/admin/system-management/image',
    CATEGORY_MANAGEMENT = '/admin/system-management/category',
    SIZE_MANAGEMENT = '/admin/system-management/size',
    SIZE_GUIDE_MANAGEMENT = '/admin/system-management/size-guide',
    DISCOUNT_MANAGEMENT = '/admin/system-management/discount',
    HISTORY_MANAGEMENT = '/admin/history-management',
    LAYOUT_MANAGEMENT = '/admin/layout-management',
    LAYOUT_SETTING = '/admin/layout-setting/:id',
    FOLLOWER_MANAGEMENT = '/admin/follower-management',
    PRODUCT_MANAGEMENT = '/admin/product-management',
    PRODUCT_EDIT = '/admin/product-edit/:id',
    CUSTOMER_MANAGEMENT = '/admin/customer-management',
    CUSTOMER_EDIT = '/admin/customer-edit/:id',
    INVOICE_MANAGEMENT = '/admin/invoice-management',
    RETURN_MANAGEMENT = '/admin/return-management',
    ORDER_MANAGEMENT = '/admin/order-management',
    PROMOTION_MANAGEMENT = '/admin/promotion-management',
    PROMOTION_EDIT = '/admin/promotion-edit/:id',
    HOMEPAGE_SETTING_MANAGEMENT = '/admin/homepage-setting/',
    BANK_MANAGEMENT = '/admin/system-management/bank',

    // UN-LOGIN USER
    VIEW = '/view',
    USER = '/user',
    PRODUCT = '/product',
    PRODUCT_DETAIL = '/view/detail/:id',
    SHOP = '/product/shop',
    CATEGORY = '/product/category/:id',
    DISCOUNT = '/product/discount',
    WISH_LIST = '/product/wish-list',
    CART = '/view/cart',
    PAYMENT = '/user/payment',
    PAYMENT_RESULT = '/user/payment-result/:result',
    PROFILE = '/user/profile',
    PROFILE_DETAIL = '/user/profile-detail',
    PURCHASE_HISTORY = '/user/purchase-history',
    USER_CHANGE_PASSWORD = '/user/change-password',
    ABOUT = '/view/about',
    CONTACT = '/view/contact',
    POLICY = '/view/policy/:tab'
}

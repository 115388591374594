import { ResponseDTO } from 'src/model/dto/base.dto';
import {
    CreateOrUpdateLayoutDTO,
    DeleteLayoutDTO,
    DeleteMultiLayoutsDTO,
    GetLayoutDTO,
    LayoutPaginationDTO
} from 'src/model/dto/layout.dto';
import { GetDistrictDTO, GetProvincesDTO } from 'src/model/dto/system.dto';
import Container, { Service } from 'typedi';
import { HttpService } from './http.service';

@Service()
export class SystemSettingServices {
    private httpServiceInstance = Container.get(HttpService);

    async createOrUpdateLayout(dto: CreateOrUpdateLayoutDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async getOne(getOneDTO: GetLayoutDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(getOneDTO);
    }

    async delete(deleteDTO: DeleteLayoutDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(deleteDTO);
    }

    async deleteLayouts(deleteDTO: DeleteMultiLayoutsDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(deleteDTO);
    }

    async pagination(getAllDTO: LayoutPaginationDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(getAllDTO);
    }

    async getProvinces(getDTO: GetProvincesDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(getDTO);
    }

    async getDistrict(getDTO: GetDistrictDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(getDTO);
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { VALIDATE_FORM } from 'src/constants/validate-form';
export const PASSWORD_PATTERN =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*^[\S]+$)(?=.*[!@#$%^&*]).{8,}$/;
export const EMAIL_PATTERN =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (value: string): Promise<any> => {
    if (!value) {
        return Promise.reject('Email không được để trống');
    }
    if (!value.match(EMAIL_PATTERN)) {
        return Promise.reject('Email không đúng định dạng');
    }
    return Promise.resolve();
};

export const validatePhone = (value: string): Promise<any> => {
    if (!value) {
        return Promise.reject(VALIDATE_FORM.MESSAGE_PHONE_IS_REQUIRED);
    }
    if (!value.match(/^[0-9]*$/)) {
        return Promise.reject(VALIDATE_FORM.MESSAGE_PHONE_IS_NUMBER);
    }
    if (!value.match(/^0[0-9]{9}$/)) {
        return Promise.reject(VALIDATE_FORM.MESSAGE_PHONE_MALFORMED);
    }
    return Promise.resolve();
};

export const validatePattern = (value: string, pattern: RegExp, message?: string): Promise<any> => {
    if (!value) {
        return Promise.reject();
    }
    if (!value.toLowerCase().match(pattern)) {
        return Promise.reject(message || VALIDATE_FORM.MESSAGE_MALFORMED);
    }
    return Promise.resolve();
};

export const validatePassword = (
    value: string,
    confirmPassword?: string | null,
    oldPassword?: string | null
): Promise<any> => {
    if (!value) {
        return Promise.reject(VALIDATE_FORM.MESSAGE_MIN_LENGTH_PASS);
    }

    if (!PASSWORD_PATTERN.test(value)) {
        return Promise.reject(VALIDATE_FORM.MESSAGE_PASS_ERROR);
    }

    if (confirmPassword && value !== confirmPassword)
        return Promise.reject(VALIDATE_FORM.MESSAGE_NOT_SAME_PASS);

    if (oldPassword && value === oldPassword)
        return Promise.reject(VALIDATE_FORM.MESSAGE_IS_SAME_OLD_PASS);

    return Promise.resolve();
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductEntryType } from 'src/constants/default';
import { ENDPOINT } from 'src/constants/endpoint';
import { METHOD } from 'src/constants/http';
import { DTO, IPagination, ResponseDTO } from './base.dto';
import { IImage } from './product.dto';

export interface ICategory {
    id?: string;
    name?: string;
    description?: string;
    type?: ProductEntryType;
    isActive?: boolean;
    isDisplay?: boolean;
    image?: IImage;
    coverImage?: IImage;
}

export interface IColor {
    id?: string;
    name?: string;
    color?: string;
    description?: string;
    type?: ProductEntryType;
    isActive?: boolean;
}

export interface ISizeGuide {
    id?: string;
    name?: string;
    description?: string;
    isActive?: boolean;
    image?: IImage;
}

export interface ISize {
    id?: string;
    name?: string;
    description?: string;
    type?: ProductEntryType;
    isActive?: boolean;
}

export interface IDiscount {
    id?: string;
    name?: string;
    percent?: string;
    isDiscountRatio?: boolean;
    discount?: number;
    description?: string;
    type?: ProductEntryType;
    isActive?: boolean;
}

export interface IProductEntry {
    id?: string;
    name?: string;
    description?: string;
    type?: ProductEntryType;
    isActive?: boolean;
    isDisplay?: boolean;
    image?: IImage;
    coverImage?: IImage;
    color?: string;
    percent?: string;
    discount?: number;
    isDiscountRatio?: boolean;
    imageId?: string;
    coverImageId?: string;
    accountNumber?: string;
}

export class CreateOrUpdateProductEntryDTO extends DTO {
    public query: IProductEntry;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_ENTRY_CREATE_OR_UPDATE;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    public body: FormData | undefined;
    public headers = { 'Content-Type': 'multipart/form-data' };
    constructor(body: FormData | undefined, query: IProductEntry) {
        super();
        this.body = body;
        this.query = query;
    }
}

export interface IGetProductEntryFilter {
    keyword?: string;
    type?: ProductEntryType;
    isActive?: boolean;
    isDisplay?: boolean;
}

export class GetProductEntryResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data:
        | {
              items: any[];
              totalItems: number;
          }
        | undefined;
}

export interface IDeleteProductEntry {
    id: number;
    type: ProductEntryType;
}

export class ProductEntryPaginationDTO extends DTO {
    public query: IPagination;
    public param: undefined;
    public url = ENDPOINT.GET_PRODUCT_ENTRY;
    public method = METHOD.POST;
    public readonly response!: GetProductEntryResponse;
    public body: IGetProductEntryFilter;
    constructor(query: IPagination, body: IGetProductEntryFilter) {
        super();
        this.query = query;
        this.body = body;
    }
}

export class DeleteProductEntryDTO extends DTO {
    public body: undefined;
    public param: IDeleteProductEntry;
    public query: undefined;
    public url = ENDPOINT.DELETE_PRODUCT_ENTRY;
    public method = METHOD.DELETE;
    public readonly response!: ResponseDTO;
    constructor(param: IDeleteProductEntry) {
        super();
        this.param = param;
    }
}

export class DeleteMultiItemDTO extends DTO {
    public body: { ids: number[]; type: ProductEntryType };
    public param: undefined;
    public query: undefined;
    public url = ENDPOINT.DELETE_MULTI_PRODUCT_ENTRY;
    public method = METHOD.DELETE;
    public readonly response!: ResponseDTO;
    constructor(body: { ids: number[]; type: ProductEntryType }) {
        super();
        this.body = body;
    }
}

export class GetAllProductEntryResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data:
        | {
              color: IColor[];
              size: ISize[];
              category: ICategory[];
              discount: IDiscount[];
              sizeGuide: ISizeGuide[];
          }
        | undefined;
}

export interface IProductEntryAll {
    color: IColor[];
    size: ISize[];
    category: ICategory[];
    discount: IDiscount[];
    sizeGuide: ISizeGuide[];
}

export class GetAllProductEntryDTO extends DTO {
    public query: undefined;
    public param: undefined;
    public url = ENDPOINT.GET_PRODUCT_ENTRY_ALL;
    public method = METHOD.POST;
    public readonly response!: GetAllProductEntryResponse;
    public body: { isActive?: boolean };
    constructor(body: { isActive?: boolean }) {
        super();
        this.body = body;
    }
}

export class SyncProductAttributeDTO extends DTO {
    public query: undefined;
    public body: undefined;
    public param: undefined;
    public url = ENDPOINT.SYNC_PRODUCT_ATTRIBUTE;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    constructor() {
        super();
    }
}

export class UpdateCategoryDisplayHomepageDTO extends DTO {
    public query: undefined;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_ENTRY_CATEGORY_DISPLAY_HOMEPAGE;
    public method = METHOD.PUT;
    public body: { ids: string[] };
    constructor(body: { ids: string[] }) {
        super();
        this.body = body;
    }
}

export class GetSaleCoverDTO extends DTO {
    public query: undefined;
    public param: undefined;
    public url = ENDPOINT.GET_SAVE_COVER;
    public method = METHOD.GET;
    public readonly response!: ResponseDTO;
    public body: undefined;
    constructor() {
        super();
    }
}

export class UpdateSaleCoverDTO extends DTO {
    public query: { id: number | undefined };
    public param: undefined;
    public url = ENDPOINT.UPDATE_SAVE_COVER;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    public body: FormData | undefined;
    public headers = { 'Content-Type': 'multipart/form-data' };
    constructor(body: FormData | undefined, query: { id: number | undefined }) {
        super();
        this.body = body;
        this.query = query;
    }
}

export enum ENDPOINT {
    BASE_URL = '',
    //Authentication
    LOGOUT = '/auth/logout',
    REGISTER = '/auth/kiot-register',
    VERIFY_INFO = '/auth/verify',
    LOGIN = '/auth/kiot-login',
    FORGOT_PASSWORD = '/auth/kiot-request-reset-password/:email',
    RESET_PASSWORD = '/auth/kiot-reset-password',
    CHANGE_PASSWORD = '/auth/kiot-change-password',
    // Product
    GET_PRODUCT_ENTRY_ALL = '/product-entry',
    GET_PRODUCT_ENTRY = '/product-entry/find-product-entry',
    DELETE_PRODUCT_ENTRY = '/product-entry/:id/:type',
    DELETE_MULTI_PRODUCT_ENTRY = '/product-entry/multi',
    HISTORY = '/audit/find',
    SYSTEM_LAYOUT_CREATE_OR_UPDATE = '/system/layout/create-or-update',
    SYSTEM_LAYOUT_GET = '/system/layout/get-layout/:id',
    SYSTEM_LAYOUT_GET_ALL = '/system/layout',
    SYSTEM_LAYOUT_DELETE = '/system/layout/delete/:id',
    SYSTEM_LAYOUT_DELETE_MULTI = '/system/layout/delete-multi',
    SYSTEM_GET_PROVINCES = '/system/province',
    SYSTEM_GET_DISTRICT = '/system/district/:provinceCode',
    COLLECTION_CREATE = '/collection/create',
    COLLECTION_UPDATE = '/collection/update',
    COLLECTION_GET = '/collection/get-collection/:id',
    COLLECTION_GET_ALL = 'collection',
    COLLECTION_DELETE = 'collection/delete/:id',
    PRODUCT_ENTRY_CREATE_OR_UPDATE = '/product-entry/create-or-update',
    PRODUCT_ENTRY_CATEGORY_DISPLAY_HOMEPAGE = '/product-entry/update-display-homepage',
    // User
    GET_USER = '/users/:id',
    GET_USERS = '/users/pagination',
    DELETE_USER = '/users/:id',
    UPDATE_USER = '/users',
    // Follower
    GET_FOLLOWERS = '/followers/pagination',
    DELETE_FOLLOWER = '/followers/:id',
    DELETE_MULTI_FOLLOWERS = '/followers/delete/multi',
    UPDATE_FOLLOWERS = '/followers/update',
    CREATE_FOLLOWERS = '/followers/create',
    //Kiot
    SYNC_PRODUCT = '/kiot-product/sync',
    SYNC_PRODUCT_BY_ID = '/kiot-product/sync/:id',
    PRODUCT_ALL = '/kiot-product',
    PRODUCT_GET_BY_ID = '/kiot-product/:id',
    PRODUCT_UPDATE = '/kiot-product/update',
    PRODUCT_GET_SAME_CATEGORY = '/kiot-product/get-products-same-category/:id',
    PRODUCT_DELETES = '/kiot-product',
    PRODUCT_UPDATE_DISPLAY_HOMEPAGE = '/kiot-product/update-display-homepage',
    // Customer
    SYNC_CUSTOMERS = '/kiot-customer/sync',
    SYNC_CUSTOMER_BY_ID = '/kiot-customer/sync/:id',
    GET_CUSTOMERS = '/kiot-customer',
    GET_CUSTOMER = '/kiot-customer/:id',
    UPDATE_CUSTOMER = '/kiot-customer/update',
    UPDATE_EMAIL = '/kiot-customer/update-email/:id',
    DELETE_CUSTOMER = '/kiot-customer/:id',
    // Invoices
    SYNC_INVOICES = '/kiot-invoices/sync',
    SYNC_INVOICE_BY_ID = '/kiot-invoices/sync/:id',
    GET_INVOICES = '/kiot-invoices',
    // Return
    SYNC_RETURNS = '/kiot-returns/sync',
    SYNC_RETURN_BY_ID = '/kiot-returns/sync/:id',
    GET_RETURNS = '/kiot-returns',
    // Orders
    SYNC_ORDERS = '/kiot-orders/sync',
    GET_ORDERS = '/kiot-orders',
    CREATE_ORDER = '/kiot-orders/create',
    SYNC_ORDER_BY_ID = '/kiot-orders/sync/:id',
    // Product Attributes
    SYNC_PRODUCT_ATTRIBUTE = '/product-entry/sync-attributes',
    // EMAIL SERVICE
    EMAIL_SEND_FEEDBACK = '/mail/feedback',
    // SAVE COVER
    UPDATE_SAVE_COVER = '/product-entry/update-save-cover',
    GET_SAVE_COVER = '/product-entry/sale-cover',
    // PROMOTION
    PROMOTION_CREATE = '/promotion/create',
    PROMOTION_UPDATE = '/promotion/update',
    PROMOTION_GET_ONE = '/promotion/get/:id',
    PROMOTION_GET_ALL = '/promotion',
    PROMOTION_DELETE = '/promotion/delete/:id',
    PROMOTION_DELETE_MULTI = '/promotion/delete-multi',
    PROMOTION_SEND_BROADCAST = '/promotion/broadcast'
}

export enum LOADING_STATUS {
    INIT = 'INIT',
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    LOADING = 'LOADING',
    EXISTED = 'EXISTED',
    PHONE_EXISTED = 'PHONE_EXISTED',
    EMAIL_EXISTED = 'EMAIL_EXISTED',
    TOKEN_NOT_FOUND = 'TOKEN_NOT_FOUND',
    FAILED = 'FAILED',
    NOT_FOUND = 'NOT_FOUND',
    EMAIL_DOES_NOT_WORK = 'EMAIL_DOES_NOT_WORK',
    OLD_PASSWORD_NOT_MATCH = 'OLD_PASSWORD_NOT_MATCH',
    TOKEN_INVALID = 'TOKEN_INVALID',
    TOKEN_EXPIRED = 'TOKEN_EXPIRED',
    OVER_QUANTITY = 'OVER_QUANTITY',
    USER_NOT_FOUND = 'USER_NOT_FOUND'
}

export enum USER_AUTH_STATUS {
    EMAIL_DOES_NOT_MATCH = 'EMAIL_DOES_NOT_MATCH',
    PASSWORD_DOES_NOT_MATCH = 'PASSWORD_DOES_NOT_MATCH',
    FAILED = 'FAILED'
}

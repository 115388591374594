/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Form, Input, Layout, message, Modal, Row, Space } from 'antd';
import styles from './register.module.scss';
import { validateEmail, validatePassword, validatePhone } from 'src/validation/user.validator';
import { useTranslation } from 'react-i18next';
import { i18nKey } from 'src/locales/i18n';
import ProductButton from 'src/components/product-button/product-button';
import { UserOutlined, MailOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { TAB_KEY } from 'src/components/modal/login/login-modal';
import { CustomerRegisterDTO, CustomerVerifyInfoDTO } from 'src/model/dto/register.dto';
import { LOADING_STATUS } from 'src/constants/status';
import Container from 'typedi';
import { CustomerServices } from 'src/services/customer.service';
import { authStore } from 'src/stores/auth.store';
import { MAX_CHARACTER_NAME, MIN_CHARACTER_NAME } from 'src/constants/default';
import firebase from 'src/utils/firebase';
import OtpInput from 'react-otp-input';
import { useRef } from 'react';
const { Content } = Layout;

const RESEND_TIMEOUT = 20; // seconds
export interface IProps {
    onFinish: (values: { email: string; password: string }) => void;
}

export enum FORM_STATUS {
    REGISTER = 1,
    PHONE_VERIFY = 2
}

export enum USER_REGISTER_FIELDS {
    EMAIL = 'email',
    PHONE = 'phone',
    NAME = 'name',
    PASSWORD = 'password',
    OTP = 'otp'
}

const FirebaseRegisterForm = ({ onFinish }: IProps) => {
    const [registerForm] = Form.useForm();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [isLoadingRegister, setLoadingRegister] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [otp, setOtp] = useState<string>();
    const [openOtp, setOpenOtp] = useState<boolean>(false);
    const [otpError, setOtpError] = useState<boolean>(false);
    const [isSending, setSending] = useState<boolean>(false);
    const [isSent, setSent] = useState<boolean>(false);
    const [isConfirmOtp, setConfirmOtp] = useState<boolean>(false);
    const [timeOut, setTimeout] = useState<number>(0);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const intervalRef = useRef<number>();
    const customerService = Container.get(CustomerServices);
    const { t } = useTranslation();

    const REGISTER_ERROR_MAP: {
        [key in LOADING_STATUS]?: { key: USER_REGISTER_FIELDS | undefined; msg: string };
    } = {
        [LOADING_STATUS.EMAIL_EXISTED]: {
            key: USER_REGISTER_FIELDS.EMAIL,
            msg: t(i18nKey.login.existedEmail)
        },
        [LOADING_STATUS.PHONE_EXISTED]: {
            key: USER_REGISTER_FIELDS.PHONE,
            msg: t(i18nKey.login.existedPhone)
        }
    };

    useEffect(() => {
        if (timeOut <= 0) {
            clearInterval(intervalRef.current);
        } else {
            intervalRef.current = window.setInterval(() => {
                setTimeout((time) => time - 1);
            }, 1000);
        }
        return () => clearInterval(intervalRef.current);
    }, [timeOut]);

    const configureCaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                size: 'invisible',
                callback: () => {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    onSendOTP();
                },
                defaultCountry: 'VI'
            });
        }
    };

    const onSendOTP = async () => {
        setSending(true);
        setSent(false);
        setOtpError(false);
        const phoneNumber = registerForm.getFieldValue(USER_REGISTER_FIELDS.PHONE);
        configureCaptcha();
        const wkPhoneNumber = '+84' + phoneNumber;
        const appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(wkPhoneNumber, appVerifier)
            .then((confirmationResult: any) => {
                setSending(false);
                setSent(true);
                setTimeout(RESEND_TIMEOUT);
                console.log('DEBUG LOG:::SMS sent success');
                window.confirmationResult = confirmationResult;
            })
            .catch((error: { code: string; message: string }) => {
                if (error.code === 'auth/too-many-requests') {
                    // Disable confirm button
                }
                setSending(false);
                console.log('DEBUG LOG:::SMS sent failed', error);
            });
    };

    // 1. Verify information (onVerifyInformation)
    // 2. Verify phone number (OTP)
    // 2.1 Send OTP to phone number (onSendOTP)
    // 2.2 Verify OTP (onVerifyOTP)
    // 3. Register (onFinishRegisterForm)
    const onVerifyInformation = () => {
        setLoadingRegister(true);
        customerService
            .verify(
                new CustomerVerifyInfoDTO({
                    email: registerForm.getFieldValue(USER_REGISTER_FIELDS.EMAIL),
                    password: registerForm.getFieldValue(USER_REGISTER_FIELDS.PASSWORD),
                    name: registerForm.getFieldValue(USER_REGISTER_FIELDS.NAME),
                    contactNumber: registerForm.getFieldValue(USER_REGISTER_FIELDS.PHONE)
                })
            )
            .then((rs) => {
                setLoadingRegister(false);
                if (rs.msgSts?.code === LOADING_STATUS.SUCCESS) {
                    onSendOTP();
                    setOpenOtp(true);
                } else if (rs.msgSts?.codes && rs.msgSts?.codes.length > 0) {
                    const messageKeys = Object.keys(REGISTER_ERROR_MAP);
                    for (const _code of rs.msgSts?.codes) {
                        const msg = messageKeys.includes(_code as LOADING_STATUS)
                            ? (_code as LOADING_STATUS)
                            : LOADING_STATUS.FAIL;
                        const error = REGISTER_ERROR_MAP[msg];

                        if (!error || !error.key) {
                            message.error(i18nKey.msgSystemError);
                            return;
                        }

                        registerForm.setFields([
                            {
                                name: error.key,
                                errors: [error.msg]
                            }
                        ]);
                    }
                } else {
                    message.error(i18nKey.msgSystemError);
                }
            });
    };

    const onFinishRegisterForm = () => {
        // Register and Login after register
        setLoadingRegister(true);
        customerService
            .register(
                new CustomerRegisterDTO({
                    email: registerForm.getFieldValue(USER_REGISTER_FIELDS.EMAIL),
                    password: registerForm.getFieldValue(USER_REGISTER_FIELDS.PASSWORD),
                    name: registerForm.getFieldValue(USER_REGISTER_FIELDS.NAME),
                    contactNumber: registerForm.getFieldValue(USER_REGISTER_FIELDS.PHONE)
                })
            )
            .then((rs) => {
                setLoadingRegister(false);
                setOpenOtp(false);
                if (rs.msgSts?.code === LOADING_STATUS.SUCCESS) {
                    // Check user exist
                    onFinish({
                        email: registerForm.getFieldValue(USER_REGISTER_FIELDS.EMAIL),
                        password: registerForm.getFieldValue(USER_REGISTER_FIELDS.PASSWORD)
                    });
                } else if (rs.msgSts?.codes && rs.msgSts?.codes.length > 0) {
                    const messageKeys = Object.keys(REGISTER_ERROR_MAP);
                    for (const _code of rs.msgSts?.codes) {
                        const msg = messageKeys.includes(_code as LOADING_STATUS)
                            ? (_code as LOADING_STATUS)
                            : LOADING_STATUS.FAIL;
                        const error = REGISTER_ERROR_MAP[msg];

                        if (!error || !error.key) {
                            message.error(i18nKey.msgSystemError);
                            return;
                        }

                        registerForm.setFields([
                            {
                                name: error.key,
                                errors: [error.msg]
                            }
                        ]);
                    }
                } else {
                    message.error(i18nKey.msgSystemError);
                }
            });
    };

    const onVerifyOTP = async () => {
        if (!otp) {
            return;
        }
        setConfirmOtp(true);
        window.confirmationResult
            .confirm(otp)
            .then(() => {
                setConfirmOtp(false);
                setOpenOtp(false);
                setOtp('');
                onFinishRegisterForm();
            })
            .catch((error: { code: string; message: string }) => {
                console.log('DEBUG LOG:::Verify OTP Failed', error);
                setConfirmOtp(false);
                setOtpError(true);
                switch (error.code) {
                    case 'auth/invalid-verification-code':
                        setErrorMsg(t(i18nKey.login.msgOTPInvalid));
                        break;

                    case 'auth/code-expired':
                        setErrorMsg(t(i18nKey.login.msgOTPExpired));
                        break;

                    default:
                        setErrorMsg(error.message);
                        break;
                }
            });
    };

    return (
        <Content>
            <div id="recaptcha-container"></div>
            <Form
                className={styles.formLogin}
                initialValues={{ remember: true }}
                onFinish={onVerifyInformation}
                form={registerForm}
                autoComplete="off"
                onFieldsChange={(_, someFields) => {
                    const fieldsValue: { [key in string]: string } = registerForm.getFieldsValue([
                        USER_REGISTER_FIELDS.NAME,
                        USER_REGISTER_FIELDS.EMAIL,
                        USER_REGISTER_FIELDS.PASSWORD,
                        USER_REGISTER_FIELDS.PHONE
                    ]);
                    const isSomeFieldBeEmpty = Object.values(fieldsValue).some((value) => !value);
                    const isSomeFieldBeError = someFields.some((field) => field.errors?.length);
                    setIsSubmitDisabled(isSomeFieldBeEmpty || isSomeFieldBeError);
                }}>
                <Row justify="center">
                    <div className={styles.loginDesc}>
                        <p>{t(i18nKey.login.registerTitle)}</p>
                        <p>{t(i18nKey.login.registerTitleSub)}</p>
                    </div>
                </Row>
                <Row justify="center">
                    <Form.Item
                        className={styles.formInput}
                        name={USER_REGISTER_FIELDS.NAME}
                        rules={[
                            {
                                whitespace: true,
                                required: true,
                                message: t(i18nKey.login.nameRequired)
                            },
                            {
                                message: t(i18nKey.msgValidateName, {
                                    min: MIN_CHARACTER_NAME,
                                    max: MAX_CHARACTER_NAME
                                }),
                                min: MIN_CHARACTER_NAME,
                                max: MAX_CHARACTER_NAME
                            }
                        ]}>
                        <Input
                            className={styles.inputField}
                            autoFocus={true}
                            prefix={<UserOutlined />}
                            placeholder={t(i18nKey.textInputNameRequired)}
                        />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Form.Item
                        className={styles.formInput}
                        name={USER_REGISTER_FIELDS.EMAIL}
                        rules={[
                            () => ({
                                validator: (_, value) => {
                                    return validateEmail(value);
                                }
                            })
                        ]}>
                        <Input
                            className={styles.inputField}
                            autoFocus={true}
                            prefix={<MailOutlined />}
                            placeholder={t(i18nKey.txtInputEmailRequired)}
                        />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Form.Item
                        className={styles.formInput}
                        name={USER_REGISTER_FIELDS.PASSWORD}
                        rules={[
                            () => ({
                                validator(_, value) {
                                    return validatePassword(value);
                                }
                            })
                        ]}>
                        <Input.Password
                            className={styles.inputField}
                            prefix={<LockOutlined />}
                            placeholder={t(i18nKey.placeRequirePassword)}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                        />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Form.Item
                        className={styles.formInput}
                        name={['confirmPassword']}
                        rules={[
                            () => ({
                                validator(_, value) {
                                    return validatePassword(value, confirmPassword);
                                }
                            })
                        ]}>
                        <Input.Password
                            placeholder={t(i18nKey.txtInputConfirmPasswordRequired)}
                            className={styles.inputField}
                            prefix={<LockOutlined />}
                        />
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <Form.Item
                        className={styles.formInput}
                        name={USER_REGISTER_FIELDS.PHONE}
                        extra={t(i18nKey.login.registerNote)}
                        rules={[
                            () => ({
                                validator(_, value) {
                                    return validatePhone(value);
                                }
                            })
                        ]}>
                        <Input
                            prefix={<PhoneOutlined />}
                            className={styles.inputField}
                            placeholder={t(i18nKey.profile.phone)}
                        />
                    </Form.Item>
                </Row>
                <Row justify="center" style={{ marginTop: '2rem' }}>
                    <Form.Item className={styles.formInput}>
                        <ProductButton
                            className={styles.btnLogin}
                            disabled={timeOut > 0 || isSubmitDisabled}
                            loading={isLoadingRegister}
                            type="primary"
                            htmlType="submit">
                            {t(i18nKey.login.verifyInformation, { timeOut })}
                        </ProductButton>
                    </Form.Item>
                </Row>
                <Row justify="center">
                    <p
                        className={styles.haveAccount}
                        onClick={() => authStore.setActiveTab(TAB_KEY.LOGIN)}>
                        {t(i18nKey.login.haveAccount)}
                    </p>
                </Row>
            </Form>
            <Modal
                title={t(i18nKey.login.verifyOTP)}
                visible={openOtp}
                centered
                onCancel={() => {
                    setOpenOtp(false);
                    setOtp('');
                }}
                footer={null}>
                <Space direction="vertical" className={styles.otpContainer}>
                    {isSent && (
                        <div className={styles.sentOtpInfo}>
                            <p>
                                {t(i18nKey.login.sentOTP, {
                                    phone: registerForm.getFieldValue(USER_REGISTER_FIELDS.PHONE)
                                })}
                            </p>
                            <p>{t(i18nKey.login.enterOTP)}</p>
                        </div>
                    )}
                    <OtpInput
                        isInputNum={true}
                        className={styles.otpInput}
                        value={otp}
                        hasErrored={otpError}
                        onChange={(value: string) => setOtp(value)}
                        numInputs={6}
                        separator={<span>-</span>}
                    />
                    {otpError && <p className={styles.otpError}>{errorMsg}</p>}
                    <Space wrap={true} align="center" direction="horizontal">
                        <ProductButton
                            disabled={!otp || otp?.length < 6}
                            onClick={onVerifyOTP}
                            loading={isConfirmOtp}>
                            {t(i18nKey.login.btnVerify)}
                        </ProductButton>
                        <ProductButton
                            loading={isSending}
                            disabled={timeOut > 0}
                            onClick={() => {
                                setOtp('');
                                onSendOTP();
                            }}>
                            {t(i18nKey.login.btnResend, { timeOut })}
                        </ProductButton>
                    </Space>
                </Space>
            </Modal>
        </Content>
    );
};

export default FirebaseRegisterForm;

import { ResponseType } from 'axios';
import { METHOD } from '../../constants/http';

export abstract class DTO {
    public abstract param: object | undefined;
    public abstract query: unknown;
    public abstract body: unknown;
    public abstract readonly url: string;
    public abstract readonly method: METHOD;
    public headers?: object;
    public responseType?: ResponseType;
}

export abstract class ResponseDTO {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public abstract readonly data: any;
    public readonly msgSts: { code: string; message: string; codes?: Array<string> } | undefined;
}

export interface IPagination {
    keyword?: string;
    sort?: string;
    sortDirection?: string;
    page: number;
    limit: number;
}

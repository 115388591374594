// export const firebase = {};
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: 'AIzaSyCyf9nzoyrI1ARbv5YsdU83slkUsbX6_CA',
    authDomain: 'otp-code-e2c5a.firebaseapp.com',
    projectId: 'otp-code-e2c5a',
    storageBucket: 'otp-code-e2c5a.appspot.com',
    messagingSenderId: '577856734512',
    appId: '1:577856734512:web:b195fee89539ec2b4c34f5',
    measurementId: 'G-N5YHG43SR2'
};
// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;

// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: 'AIzaSyCyf9nzoyrI1ARbv5YsdU83slkUsbX6_CA',
//     authDomain: 'otp-code-e2c5a.firebaseapp.com',
//     projectId: 'otp-code-e2c5a',
//     storageBucket: 'otp-code-e2c5a.appspot.com',
//     messagingSenderId: '577856734512',
//     appId: '1:577856734512:web:b195fee89539ec2b4c34f5',
//     measurementId: 'G-N5YHG43SR2'
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

import { observer } from 'mobx-react';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { authStore } from 'src/stores/auth.store';
import { PAGE_ROUTE } from '../../constants/route';

interface IAuthRouter {
    children?: React.ReactElement;
    path: string;
    component?: React.ComponentType;
}

const AdminRouter = observer(
    ({ path, component, children, ...props }: IAuthRouter & RouteProps) => {
        // Constantly ping user to check if token expired
        if (!authStore.isAdmin()) {
            return <Redirect to={PAGE_ROUTE.HOME} />;
        }

        return (
            <Route path={path} component={component} {...props}>
                {children}
            </Route>
        );
    }
);

export default AdminRouter;

import { DTO } from './base.dto';
import { METHOD } from '../../constants/http';
import { ENDPOINT } from 'src/constants/endpoint';

export interface ILoginBody {
    email: string;
    password: string;
}

export class LoginResponse {
    accessToken: string;
    id: string;
    email: string;
    fullName: string;
    roleName: string;
    constructor(data: {
        accessToken: string;
        email: string;
        fullName: string;
        roleName: string;
        id: string;
    }) {
        this.id = data.id;
        this.accessToken = data.accessToken;
        this.email = data.email;
        this.fullName = data.fullName;
        this.roleName = data.roleName;
    }
}

export class LoginDTO extends DTO {
    public param: undefined;
    public url = ENDPOINT.LOGIN;
    public method = METHOD.POST;
    body: ILoginBody;
    public readonly responseClass = LoginResponse;
    public query: undefined;
    constructor(body: ILoginBody) {
        super();
        this.body = body;
    }
}

import React from 'react';
import { ProductAllMode } from 'src/model/dto/product.dto';
import { PAGE_ROUTE } from './route';

export enum PORTAL_MENU {
    PRODUCT_MANAGEMENT = 'titleProduct',
    ORDER_MANAGEMENT = 'titleOrderManagement',
    INVOICE_MANAGEMENT = 'titleInvoiceManagement',
    RETURN_MANAGEMENT = 'titleReturnManagement',
    CUSTOMER_MANAGEMENT = 'titleCustomerManagement',
    SYSTEM_MANAGEMENT = 'titleSystemManagement',
    COLOR_MANAGEMENT = 'titleColorManagement',
    SIZE_MANAGEMENT = 'titleSizeManagement',
    SIZE_GUIDE_MANAGEMENT = 'titleSizeGuideManagement',
    CATEGORY_MANAGEMENT = 'titleCategoryManagement',
    DISCOUNT_MANAGEMENT = 'titleDiscountManagement',
    LAYOUT_MANAGEMENT = 'titleLayoutManagement',
    IMAGE_MANAGEMENT = 'titleImageManagement',
    // HISTORY_MANAGEMENT = 'titleHistoryManagement',
    HOMEPAGE_SETTING_MANAGEMENT = 'titleHomepageSettingManagement',
    BANK_MANAGEMENT = 'titleBankManagement',
    FOLLOWER_MANAGEMENT = 'titleFollowerManagement',
    PROMOTION_MANAGEMENT = 'titlePromotionManagement',
    CHANGE_PASSWORD = 'titleChangePassword',
    LOGOUT = 'titleLogout'
}

export enum PORTAL_TITLE {
    PRODUCT_MANAGEMENT = 'titleProduct',
    ORDER_MANAGEMENT = 'titleOrderManagement',
    INVOICE_MANAGEMENT = 'titleInvoiceManagement',
    RETURN_MANAGEMENT = 'titleReturnManagement',
    CUSTOMER_MANAGEMENT = 'titleCustomerManagement',
    SYSTEM_MANAGEMENT = 'titleSystemManagement',
    COLOR_MANAGEMENT = 'titleColorManagement',
    SIZE_MANAGEMENT = 'titleSizeManagement',
    SIZE_GUIDE_MANAGEMENT = 'titleSizeGuideManagement',
    CATEGORY_MANAGEMENT = 'titleCategoryManagement',
    DISCOUNT_MANAGEMENT = 'titleDiscountManagement',
    LAYOUT_MANAGEMENT = 'titleLayoutManagement',
    IMAGE_MANAGEMENT = 'titleImageManagement',
    // HISTORY_MANAGEMENT = 'titleHistoryManagement',
    HOMEPAGE_SETTING_MANAGEMENT = 'titleHomepageSettingManagement',
    BANK_MANAGEMENT = 'titleBankManagement',
    FOLLOWER_MANAGEMENT = 'titleFollowerManagement',
    PROMOTION_MANAGEMENT = 'titlePromotionManagement',
    CHANGE_PASSWORD = 'titleChangePassword',
    LOGOUT = 'titleLogout'
}

export const PORTAL_PATH_MAP: { [key in string]: { menu: PORTAL_MENU; title: PORTAL_TITLE } } = {
    // [PAGE_ROUTE.HISTORY_MANAGEMENT]: {
    //     menu: PORTAL_MENU.HISTORY_MANAGEMENT,
    //     title: PORTAL_TITLE.HISTORY_MANAGEMENT
    // },
    [PAGE_ROUTE.LAYOUT_MANAGEMENT]: {
        menu: PORTAL_MENU.LAYOUT_MANAGEMENT,
        title: PORTAL_TITLE.LAYOUT_MANAGEMENT
    },
    [PAGE_ROUTE.FOLLOWER_MANAGEMENT]: {
        menu: PORTAL_MENU.FOLLOWER_MANAGEMENT,
        title: PORTAL_TITLE.FOLLOWER_MANAGEMENT
    },
    [PAGE_ROUTE.PROMOTION_MANAGEMENT]: {
        menu: PORTAL_MENU.PROMOTION_MANAGEMENT,
        title: PORTAL_TITLE.PROMOTION_MANAGEMENT
    },
    [PAGE_ROUTE.SYSTEM_MANAGEMENT]: {
        menu: PORTAL_MENU.SYSTEM_MANAGEMENT,
        title: PORTAL_TITLE.SYSTEM_MANAGEMENT
    },
    [PAGE_ROUTE.COLOR_MANAGEMENT]: {
        menu: PORTAL_MENU.COLOR_MANAGEMENT,
        title: PORTAL_TITLE.COLOR_MANAGEMENT
    },
    [PAGE_ROUTE.CATEGORY_MANAGEMENT]: {
        menu: PORTAL_MENU.CATEGORY_MANAGEMENT,
        title: PORTAL_TITLE.CATEGORY_MANAGEMENT
    },
    [PAGE_ROUTE.DISCOUNT_MANAGEMENT]: {
        menu: PORTAL_MENU.DISCOUNT_MANAGEMENT,
        title: PORTAL_TITLE.DISCOUNT_MANAGEMENT
    },
    [PAGE_ROUTE.SIZE_MANAGEMENT]: {
        menu: PORTAL_MENU.SIZE_MANAGEMENT,
        title: PORTAL_TITLE.SIZE_MANAGEMENT
    },
    [PAGE_ROUTE.SIZE_GUIDE_MANAGEMENT]: {
        menu: PORTAL_MENU.SIZE_GUIDE_MANAGEMENT,
        title: PORTAL_TITLE.SIZE_GUIDE_MANAGEMENT
    },
    [PAGE_ROUTE.IMAGE_MANAGEMENT]: {
        menu: PORTAL_MENU.IMAGE_MANAGEMENT,
        title: PORTAL_TITLE.IMAGE_MANAGEMENT
    },
    [PAGE_ROUTE.BANK_MANAGEMENT]: {
        menu: PORTAL_MENU.BANK_MANAGEMENT,
        title: PORTAL_TITLE.BANK_MANAGEMENT
    },
    [PAGE_ROUTE.CHANGE_PASSWORD]: {
        menu: PORTAL_MENU.CHANGE_PASSWORD,
        title: PORTAL_TITLE.CHANGE_PASSWORD
    },
    [PAGE_ROUTE.PRODUCT_MANAGEMENT]: {
        menu: PORTAL_MENU.PRODUCT_MANAGEMENT,
        title: PORTAL_TITLE.PRODUCT_MANAGEMENT
    },
    [PAGE_ROUTE.CUSTOMER_MANAGEMENT]: {
        menu: PORTAL_MENU.CUSTOMER_MANAGEMENT,
        title: PORTAL_TITLE.CUSTOMER_MANAGEMENT
    },
    [PAGE_ROUTE.INVOICE_MANAGEMENT]: {
        menu: PORTAL_MENU.INVOICE_MANAGEMENT,
        title: PORTAL_TITLE.INVOICE_MANAGEMENT
    },
    [PAGE_ROUTE.ORDER_MANAGEMENT]: {
        menu: PORTAL_MENU.ORDER_MANAGEMENT,
        title: PORTAL_TITLE.ORDER_MANAGEMENT
    },
    [PAGE_ROUTE.RETURN_MANAGEMENT]: {
        menu: PORTAL_MENU.RETURN_MANAGEMENT,
        title: PORTAL_TITLE.RETURN_MANAGEMENT
    },
    [PAGE_ROUTE.HOMEPAGE_SETTING_MANAGEMENT]: {
        menu: PORTAL_MENU.HOMEPAGE_SETTING_MANAGEMENT,
        title: PORTAL_TITLE.HOMEPAGE_SETTING_MANAGEMENT
    }
};

export enum BREADCRUMB_MENU {
    BREADCRUMB_SHOP = 'menuCategory',
    BREADCRUMB_CATEGORY = 'menuCategory',
    BREADCRUMB_DISCOUNT = 'menuSales',
    BREADCRUMB_WISH_LIST = 'menuSaved'
}

export const PORTAL_BREADCRUMB_MAP: {
    [key in string]: { title: BREADCRUMB_MENU; subTitle: string; link: string };
} = {
    [PAGE_ROUTE.SHOP]: {
        title: BREADCRUMB_MENU.BREADCRUMB_SHOP,
        subTitle: '',
        link: PAGE_ROUTE.SHOP
    },
    [PAGE_ROUTE.CATEGORY]: {
        title: BREADCRUMB_MENU.BREADCRUMB_CATEGORY,
        subTitle: ProductAllMode.CATEGORY,
        link: PAGE_ROUTE.SHOP
    },
    [PAGE_ROUTE.DISCOUNT]: {
        title: BREADCRUMB_MENU.BREADCRUMB_DISCOUNT,
        subTitle: ProductAllMode.DISCOUNT,
        link: PAGE_ROUTE.SHOP
    },
    [PAGE_ROUTE.WISH_LIST]: {
        title: BREADCRUMB_MENU.BREADCRUMB_WISH_LIST,
        subTitle: '',
        link: ''
    }
};

export interface IMenuConfig<T> {
    text: T;
    icon?: React.ReactNode;
    onClick: () => void;
    subMenu?: {
        text: PORTAL_MENU;
        icon?: React.ReactNode;
        onClick: () => void;
    }[];
}

export enum BREAKPOINT_CONFIG {
    LARGE_SCREEN = 1220,
    DESKTOP = 1024,
    LARGE_TABLET = 961,
    TABLET = 768,
    MOBILE = 576,
    // Grid
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
    XXL = 1600
}

import { DTO, IPagination, ResponseDTO } from './base.dto';
import { METHOD } from '../../constants/http';
import { ENDPOINT } from 'src/constants/endpoint';

export enum CUSTOMER_KEYS {
    ID = 'id',
    NAME = 'name',
    CODE = 'code',
    EMAIL = 'email',
    CONTACT_NUMBER = 'contactNumber',
    ADDRESS = 'address',
    ROLE = 'role',
    LOCATION_NAME = 'locationName',
    WARD_NAME = 'wardName',
    BIRTHDAY = 'birthDate',
    DEPT = 'debt',
    TOTAL_INVOICED = 'totalInvoiced',
    CUSTOMER_ID = 'customerId',
    TOTAL_REVENUE = 'totalRevenue',
    TOTAL_POINT = 'totalPoint',
    REWARD_POINT = 'rewardPoint',
    CUSTOMER_TYPE = 'customerType',
    UPDATE_DATE = 'updatedAt',
    HEIGHT = 'height',
    WEIGHT = 'weight'
}

export interface ICustomer {
    id: number;
    customerId: number;
    name: string;
    code: string;
    contactNumber: string;
    email: string;
    birthDate: string;
    address: string;
    locationName: string;
    wardName: string;
    debt: number;
    role: string;
    gender?: boolean;
    totalRevenue?: number;
    totalPoint?: number;
    rewardPoint?: number;
    height?: number;
    weight?: number;
}

export class GetCustomerResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data: ICustomer | undefined;
}

export class GetCustomerDTO extends DTO {
    public param: { id: number };
    public url = ENDPOINT.GET_CUSTOMER;
    public method = METHOD.GET;
    public body: undefined;
    public readonly responseClass = GetCustomerResponse;
    public query: undefined;
    constructor(param: { id: number }) {
        super();
        this.param = param;
    }
}

export class GetAllCustomersResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data:
        | {
              items: ICustomer[];
              totalItems: number;
          }
        | undefined;
}

export interface IGetCustomersFilter {
    keyword: string;
    fromDate?: string;
    toDate?: string;
}

export class GetAllCustomersDTO extends DTO {
    public query: IPagination;
    public param: undefined;
    public url = ENDPOINT.GET_CUSTOMERS;
    public method = METHOD.POST;
    public readonly response!: GetAllCustomersResponse;
    public body: IGetCustomersFilter | undefined;
    constructor(query: IPagination, body: IGetCustomersFilter | undefined) {
        super();
        this.query = query;
        this.body = body;
    }
}

export interface IGetCustomerParam {
    id: number;
}

export class UpdateCustomerDTO extends DTO {
    public body: ICustomer | undefined;
    public param: undefined;
    public url = ENDPOINT.UPDATE_CUSTOMER;
    public method = METHOD.PUT;
    public readonly responseClass = GetCustomerResponse;
    public query: undefined;
    public headers?: object | undefined;
    constructor(body: ICustomer, userId: number | undefined) {
        super();
        this.body = body;
        userId &&
            (this.headers = {
                'x-amzn-oidc-identity': userId
            });
    }
}

export class SyncCustomerDTO extends DTO {
    public query: undefined;
    public body: { pageSize: number; customerIds?: number[] };
    public param: undefined;
    public url = ENDPOINT.SYNC_CUSTOMERS;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    constructor(body: { pageSize: number; customerIds?: number[] }) {
        super();
        this.body = body;
    }
}

export class SyncCustomerByIdDTO extends DTO {
    public query: undefined;
    public body: undefined;
    public param: { id?: number };
    public url = ENDPOINT.SYNC_CUSTOMER_BY_ID;
    public method = METHOD.PUT;
    public readonly response!: ResponseDTO;
    constructor(param: { id?: number }) {
        super();
        this.param = param;
    }
}

export class UpdateEmailDTO extends DTO {
    public body: { email: string; password: string };
    public param: { id: number };
    public url = ENDPOINT.UPDATE_EMAIL;
    public method = METHOD.PUT;
    public readonly response!: ResponseDTO;
    public query: undefined;
    public headers?: object | undefined;
    constructor(body: { email: string; password: string }, param: { id: number }) {
        super();
        this.body = body;
        this.param = param;
    }
}

export class DeleteCustomerDTO extends DTO {
    public body: undefined;
    public param: { id: number };
    public query: undefined;
    public url = ENDPOINT.DELETE_CUSTOMER;
    public method = METHOD.DELETE;
    public readonly response!: ResponseDTO;
    constructor(param: { id: number }) {
        super();
        this.param = param;
    }
}

import { action, makeAutoObservable, observable } from 'mobx';
import Container, { Service } from 'typedi';
import { HttpService } from 'src/services/http.service';
import { ProductEntryPaginationDTO, ICategory, IDiscount } from 'src/model/dto/product-entry.dto';
import { FE_SORT_DIRECTION, PAGINATION_DEFAULT, ProductEntryType } from 'src/constants/default';
import { ProductEntryServices } from 'src/services/product-entry.service';
import { LOADING_STATUS } from 'src/constants/status';
import { ILayout, LayoutPaginationDTO } from 'src/model/dto/layout.dto';
import { SystemSettingServices } from 'src/services/system-setting.service';
import { ProductServices } from 'src/services/product.service';
import { GetProductsDTO, IProduct } from 'src/model/dto/product.dto';
export const ORDER_KEY = 'orders';

@Service()
export class ProductStoreImplement {
    public categories: ICategory[] = [];
    public layout: ILayout[] = [];
    public trendingProducts: IProduct[] = [];
    public discounts: IDiscount[] = [];
    httpService = Container.get(HttpService);
    private productEntryService = Container.get(ProductEntryServices);
    private systemSettingService = Container.get(SystemSettingServices);
    private productServices = Container.get(ProductServices);

    constructor() {
        makeAutoObservable(this, {
            categories: observable,
            layout: observable,
            trendingProducts: observable,
            discounts: observable,
            setCategories: action,
            setLayout: action,
            setTrendingProducts: action
        });
    }

    public setCategories(orders: ICategory[]) {
        this.categories = [...orders];
    }

    public setLayout(layout: ILayout[]) {
        this.layout = [...layout];
    }

    public setTrendingProducts(trendingProducts: IProduct[]) {
        this.trendingProducts = [...trendingProducts];
    }

    public setDiscounts(discounts: IDiscount[]) {
        this.discounts = [...discounts];
    }

    public async getCategories() {
        if (this.categories && this.categories.length > 0) {
            return this.categories;
        }

        return await this.loadCategories();
    }

    public async getLayout() {
        if (this.layout && this.layout.length > 0) {
            return this.layout;
        }

        return await this.loadLayout();
    }

    public async getTrendingProducts() {
        if (this.trendingProducts && this.trendingProducts.length > 0) {
            return this.trendingProducts;
        }
        return await this.loadTrendingProducts();
    }

    public async getDiscounts() {
        if (this.discounts && this.discounts.length > 0) {
            return this.discounts;
        }
        return await this.loadDiscounts();
    }

    private async loadCategories(): Promise<ICategory[]> {
        const getDataDto = new ProductEntryPaginationDTO(
            {
                page: PAGINATION_DEFAULT.PAGE_DEFAULT,
                limit: PAGINATION_DEFAULT.SIZE_NO_LIMIT,
                sort: 'updatedAt',
                sortDirection: FE_SORT_DIRECTION.DECS
            },
            {
                type: ProductEntryType.Category,
                isActive: true,
                isDisplay: true
            }
        );

        return this.productEntryService.getProductEntryByType(getDataDto).then((rs) => {
            const { data, msgSts } = rs;
            if (msgSts?.code === LOADING_STATUS.SUCCESS && data?.items) {
                this.setCategories(data.items);
                return data.items;
            } else {
                return [];
            }
        });
    }

    private async loadLayout(): Promise<ILayout[]> {
        const getProductDto = new LayoutPaginationDTO(
            { page: PAGINATION_DEFAULT.PAGE_DEFAULT, limit: PAGINATION_DEFAULT.SIZE_DISPLAY_HOME },
            { isActive: true }
        );
        const result = await this.systemSettingService.pagination(getProductDto);
        const { data, msgSts: messageStatus } = result;
        if (messageStatus?.code === LOADING_STATUS.SUCCESS && data && data.items) {
            this.setLayout(data.items);
            return data.items;
        }
        return [];
    }

    private async loadTrendingProducts(): Promise<IProduct[]> {
        const getProductDto = new GetProductsDTO(
            {
                page: PAGINATION_DEFAULT.PAGE_DEFAULT,
                limit: PAGINATION_DEFAULT.SIZE_PRODUCT,
                sort: 'updatedAt',
                sortDirection: FE_SORT_DIRECTION.DECS
            },
            { isCategoryActive: true, isDisplay: true, isActive: true, isDisplayHomePage: true }
        );
        return this.productServices.pagination(getProductDto).then((result) => {
            const { data, msgSts: messageStatus } = result;
            if (messageStatus?.code === LOADING_STATUS.SUCCESS && data && data.items) {
                this.setTrendingProducts(data.items);
                return data.items;
            } else {
                return [];
            }
        });
    }

    private async loadDiscounts(): Promise<IDiscount[]> {
        const getDataDto = new ProductEntryPaginationDTO(
            { page: PAGINATION_DEFAULT.PAGE_DEFAULT, limit: PAGINATION_DEFAULT.SIZE_NO_LIMIT },
            {
                type: ProductEntryType.Discount,
                isActive: true
            }
        );
        const rs = await this.productEntryService.getProductEntryByType(getDataDto);
        const { data, msgSts } = rs;
        if (msgSts?.code === LOADING_STATUS.SUCCESS && data && data.items) {
            this.setDiscounts(data.items);
            return data.items;
        }
        return [];
    }
}

export const productStore = new ProductStoreImplement();

import { ResponseDTO } from 'src/model/dto/base.dto';
import {
    DeleteProductsDTO,
    GetProductByIdDTO,
    GetProductResponse,
    GetProductsDTO,
    GetProductsResponse,
    GetSameProductsDTO,
    SyncProductByIdDTO,
    SyncProductDTO,
    UpdateDisplayHomepageDTO,
    UpdateProductDTO
} from 'src/model/dto/product.dto';
import Container, { Service } from 'typedi';
import { HttpService } from './http.service';

@Service()
export class ProductServices {
    private httpServiceInstance = Container.get(HttpService);

    async syncProduct(syncDTO: SyncProductDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(syncDTO);
    }

    async syncProductById(syncDTO: SyncProductByIdDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(syncDTO);
    }

    async pagination(getDTO: GetProductsDTO): Promise<GetProductsResponse> {
        return this.httpServiceInstance.request(getDTO);
    }

    async updateProduct(updateDTO: UpdateProductDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(updateDTO);
    }

    async getById(getDTO: GetProductByIdDTO): Promise<GetProductResponse> {
        return this.httpServiceInstance.request(getDTO);
    }

    async getProductsSameCategory(getDto: GetSameProductsDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(getDto);
    }

    async deleteProducts(dto: DeleteProductsDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(dto);
    }

    async updateDisplayHomePage(dto: UpdateDisplayHomepageDTO): Promise<GetProductResponse> {
        return this.httpServiceInstance.request(dto);
    }
}

export enum VALIDATE_FORM {
    MESSAGE_ONLY_NUMBER = 'Mã đại lý chỉ là số !',
    MESSAGE_LIMIT_LENGTH_AGENT_CODE = 'Mã đại lý có 10 chữ số !',
    MESSAGE_MIN_LENGTH_PASS = 'Mật khẩu phải có ít nhất 8 kí tự',
    MESSAGE_PASS_ERROR = 'Mật khẩu phải có ít nhất 8 kí tự bao gồm chữ thường, chữ in hoa, số, và kí tự đặc biệt',
    MESSAGE_NOT_SAME_PASS = 'Mật khẩu bạn vừa nhập không khớp nhau',
    MESSAGE_IS_SAME_OLD_PASS = 'Mật khẩu mới không được trùng mật khẩu cũ',
    MESSAGE_EMAIL_MALFORMED = 'Email sai định dạng',
    MESSAGE_MALFORMED = 'Dữ liệu sai định dạng',
    MESSAGE_BANCAS_EMAIL_MALFORMED = 'Email phải có định dạng @mbageas.life',
    MESSAGE_PHONE_IS_REQUIRED = 'Số điện thoại không được để trống',
    MESSAGE_PHONE_IS_NUMBER = 'Số điện thoại chỉ chứa chữ số 0 - 9',
    MESSAGE_PHONE_MALFORMED = 'Số điện thoại gồm 10 chữ số theo quy tắc 0xxxxxxxxx',
    LIMIT_LENGTH_AGENT_CODE = 10,
    MESSAGE_URL_MALFORMED = 'URL sai định dạng'
}

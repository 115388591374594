export enum METHOD {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE'
}

export enum HTTP_STATUS_CODE {
	SUCCESS ='200'
}

import { DTO, IPagination, ResponseDTO } from './base.dto';
import { METHOD } from 'src/constants/http';
import { ENDPOINT } from 'src/constants/endpoint';
import { PRODUCT_MODE } from 'src/constants/default';
import { IColor, IDiscount, ISize, ISizeGuide } from './product-entry.dto';

export interface IImage {
    id: number;
    name: string;
    url: string;
    description: string;
}
export interface IProduct {
    id?: number;
    productId?: number;
    name?: string;
    price?: number;
    description?: string;
    descriptionEn?: string;
    color?: IColor;
    size?: ISize;
    quantity?: number;
    onHand?: number;
    reserved?: number;
    discount?: IDiscount;
    image?: IImage;
    optionImages?: IImage[];
    category?: string;
    sizeGuide?: ISizeGuide;
    isActive?: boolean;
    isDisplay?: boolean;
    isDisplayHomePage?: boolean;
    code: string;
    sameColorProducts?: IProduct[];
    sameSizeProducts?: IProduct[];
    discountNumber?: number;
    isDiscountNumber?: boolean;
    sameSizeProductIds?: number[];
    sameColorProductIds?: number[];
}

export interface IProductQuery {
    id?: string;
    name: string;
    price?: number;
    description: string;
    descriptionEn?: string;
    colorId: string;
    sizeId: string;
    discountId: string;
    imageId?: string;
    quantity?: number;
    categoryId?: string;
    sameSizeProductIds?: number[];
    sameColorProductIds?: number[];
    isDisplay: boolean;
    remainOptionImageIds?: number[];
    sizeGuideId?: number;
    discountNumber?: number;
    isDiscountNumber?: boolean;
}

export type IProductBody = FormData | undefined;

export class SyncProductDTO extends DTO {
    public query: undefined;
    public body: { pageSize: number };
    public param: undefined;
    public url = ENDPOINT.SYNC_PRODUCT;
    public method = METHOD.POST;
    public readonly response!: ResponseDTO;
    constructor(body: { pageSize: number }) {
        super();
        this.body = body;
    }
}

export class SyncProductByIdDTO extends DTO {
    public query: undefined;
    public body: undefined;
    public param: { id: number | undefined };
    public url = ENDPOINT.SYNC_PRODUCT_BY_ID;
    public method = METHOD.PUT;
    public readonly response!: ResponseDTO;
    constructor(param: { id: number | undefined }) {
        super();
        this.param = param;
    }
}

export class GetProductsResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data:
        | {
              items: IProduct[];
              totalItems: number;
          }
        | undefined;
}

export interface IProductFilter {
    ids?: number[];
    productIds?: number[];
    name?: string;
    color?: number[];
    size?: number[];
    sizeGuide?: number[];
    category?: number[];
    discount?: number[];
    keyword?: string;
    fromDate?: string;
    toDate?: string;
    isCategoryActive?: boolean;
    isDisplay?: boolean;
    isActive?: boolean;
    isDisplayHomePage?: boolean;
    isDiscount?: boolean;
}

export class GetProductsDTO extends DTO {
    public query: IPagination;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_ALL;
    public method = METHOD.POST;
    public readonly response!: GetProductsResponse;
    public body: IProductFilter | undefined;
    constructor(query: IPagination, body: IProductFilter | undefined) {
        super();
        this.query = query;
        this.body = body;
    }
}

export class UpdateProductDTO extends DTO {
    public body: IProductBody | undefined;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_UPDATE;
    public method = METHOD.PUT;
    public readonly response!: ResponseDTO;
    public query: IProductQuery;
    public headers = { 'Content-Type': 'multipart/form-data' };
    constructor(body: IProductBody, query: IProductQuery) {
        super();
        this.body = body;
        this.query = query;
    }
}

export class GetProductResponse extends ResponseDTO {
    constructor() {
        super();
    }
    public readonly data: IProduct | undefined;
}
export class GetProductByIdDTO extends DTO {
    public query: { isDisplay?: boolean } | undefined;
    public param: { id: number | undefined };
    public url = ENDPOINT.PRODUCT_GET_BY_ID;
    public method = METHOD.GET;
    public readonly response!: GetProductResponse;
    public body: IProductFilter | undefined;
    constructor(param: { id: number | undefined }, query?: { isDisplay?: boolean }) {
        super();
        this.param = param;
        this.query = query;
    }
}
export class GetSameProductsDTO extends DTO {
    public query: undefined;
    public param: { id: string };
    public url = ENDPOINT.PRODUCT_GET_SAME_CATEGORY;
    public method = METHOD.GET;
    public readonly response!: GetProductsResponse;
    public body: undefined;
    constructor(param: { id: string }) {
        super();
        this.param = param;
    }
}

export class DeleteProductsDTO extends DTO {
    public query: undefined;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_DELETES;
    public method = METHOD.DELETE;
    public body: { ids: number[] };
    constructor(body: { ids: number[] }) {
        super();
        this.body = body;
    }
}

export class UpdateDisplayHomepageDTO extends DTO {
    public query: undefined;
    public param: undefined;
    public url = ENDPOINT.PRODUCT_UPDATE_DISPLAY_HOMEPAGE;
    public method = METHOD.PUT;
    public body: { ids: string[] };
    constructor(body: { ids: string[] }) {
        super();
        this.body = body;
    }
}

export interface IProductParam {
    mode: PRODUCT_MODE;
    id: string;
}

export enum ProductAllMode {
    CATEGORY = 'category',
    DISCOUNT = 'discount'
}

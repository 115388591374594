/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { ENDPOINT } from 'src/constants/endpoint';
import { Service } from 'typedi';
import { BG_SERVICE } from '../environment/environment';
import { DTO, ResponseDTO } from '../model/dto/base.dto';
import { message } from 'antd';
import jwtDecode from 'jwt-decode';
import { USER_ROLE } from 'src/constants/default';
import { LOADING_STATUS } from 'src/constants/status';
export const USER_ACCESS_TOKEN = 'access_token';

@Service()
export class HttpService {
    public token: string | undefined;
    constructor() {
        this.token = localStorage.getItem(USER_ACCESS_TOKEN) || undefined;
        axios.interceptors.response.use(
            (response: AxiosResponse<ResponseDTO>) => {
                if (response.data && response.data.data && response.data.data.access_token) {
                    localStorage.setItem(USER_ACCESS_TOKEN, response.data.data.access_token);
                    this.token = response.data.data.access_token;
                }
                return response;
            },
            (error) => {
                // TODO: update error_code to message (discus with BE)
                if (error && error.response && error.response.data && error.response.data.msgSts) {
                    if (error.response.data.msgSts.message === LOADING_STATUS.TOKEN_INVALID) {
                        localStorage.clear();
                        this.token = undefined;
                        window.location.href = '/';
                    }
                    if (error.response.data.msgSts.message === LOADING_STATUS.FAILED) {
                        message.error('System error');
                    }
                    return error.response;
                }
                return Promise.reject(error.response);
            }
        );
    }

    public getTokenInfo(): { exp: number; userId: number; role: USER_ROLE } | undefined {
        return this.token ? jwtDecode(this.token) : undefined;
    }

    public deleteAccessToken() {
        this.token = undefined;
        localStorage.removeItem(USER_ACCESS_TOKEN);
    }

    public async request<T extends DTO>(dto: T): Promise<ResponseDTO> {
        try {
            const response = await axios({
                method: dto.method,
                headers: {
                    ...dto.headers,
                    Authorization: this.token ? 'Bearer ' + this.token : '',
                    'Access-Control-Allow-Origin': '*'
                },
                baseURL: BG_SERVICE + ENDPOINT.BASE_URL,
                url: this.replaceURLToParamURL(dto.url, dto.param || {}),
                data: dto.body,
                params: dto.query,
                responseType: dto.responseType
            });
            return { data: response.data.data, msgSts: response.data.msgSts };
        } catch (errorResponse: any) {
            return { data: errorResponse.data?.data, msgSts: errorResponse.data?.msgSts };
        }
    }

    private replaceURLToParamURL = (url: string, param: object): string => {
        let newUrl = url;
        Object.entries(param).forEach(([key, value]) => {
            newUrl = newUrl.replace(':' + key.toString(), value.toString());
        });
        return newUrl;
    };
}

import { ResponseDTO } from 'src/model/dto/base.dto';
import {
    DeleteCustomerDTO,
    GetAllCustomersDTO,
    GetAllCustomersResponse,
    GetCustomerDTO,
    GetCustomerResponse,
    SyncCustomerByIdDTO,
    SyncCustomerDTO,
    UpdateCustomerDTO,
    UpdateEmailDTO
} from 'src/model/dto/customer.dto';
import { CustomerRegisterDTO, CustomerVerifyInfoDTO } from 'src/model/dto/register.dto';
import Container, { Service } from 'typedi';
import { HttpService } from './http.service';

@Service()
export class CustomerServices {
    private httpServiceInstance = Container.get(HttpService);
    async register(registerDTO: CustomerRegisterDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(registerDTO);
    }

    async verify(registerDTO: CustomerVerifyInfoDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(registerDTO);
    }

    async getCustomer(getDTO: GetCustomerDTO): Promise<GetCustomerResponse> {
        return this.httpServiceInstance.request(getDTO);
    }

    async updateCustomer(updateDTO: UpdateCustomerDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(updateDTO);
    }

    async pagination(getAllDTO: GetAllCustomersDTO): Promise<GetAllCustomersResponse> {
        return this.httpServiceInstance.request(getAllDTO);
    }

    async deleteCustomer(deleteDTO: DeleteCustomerDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(deleteDTO);
    }

    async syncCustomer(syncDTO: SyncCustomerDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(syncDTO);
    }

    async syncCustomerById(syncDTO: SyncCustomerByIdDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(syncDTO);
    }

    async updateEmail(updateDTO: UpdateEmailDTO): Promise<ResponseDTO> {
        return this.httpServiceInstance.request(updateDTO);
    }
}
